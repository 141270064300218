<template>
    <div class="degreeReport">
        <div class="search_container searchArea">
            <el-row>
                <el-col :span="8" :offset="8">
                    <el-input v-model="degreeForm.name" placeholder="达成度名称">
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-button type="primary"  @click="addDegree">新建达成度</el-button>
                </el-col>
            </el-row>
            <el-row v-for="(dg, dgIdx) in degreeForm.degreeList" v-if="degreeForm.degreeList.length > 0" class="exam-info">
                <el-col :span="1" >
                    名称：
                </el-col>
                <el-col :span="4" >
                    <el-input v-model="dg.name" />
                </el-col>
                <el-col :span="18" :offset="1">
                    <el-row>
                        <el-col :span="8">
                            学年学期：
                            <el-select v-model="acadyearTerm" filterable placeholder="请选择" style="min-width: 200px;" @change="examFilter">
                                <el-option key="" label="全部" value=""></el-option>
                                <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="12">
                            考试：
                            <el-select v-model="dg.curExamId" filterable placeholder="请选择" style="min-width: 450px;" >
                                <el-option v-for="item in examFilterList" :key="item.id" :label="item.name+'('+item.id+')'" :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="2">
                            <el-button type="primary"  @click="addExam(dgIdx)">添加</el-button>
                        </el-col>
                        <el-col :span="2">
                            <el-button type="danger"  @click="deleteExam(dgIdx)">删除</el-button>
                        </el-col>
                    </el-row>


                </el-col>
                <el-col :span="24" v-if="dg.list.length > 0" >
                    <div class="exam-info">
                            <el-row v-for="(item, index) in dg.list" style="margin-top: 15px;">
                                <el-col :span="16">
                                    {{item.name}}({{item.id}})
                                </el-col>
                                <el-col :span="3">
                                    <el-input v-model="item.percent" placeholder="占比">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-col>
                                <el-col :span="4" :offset="1">
                                    <el-button type="danger"  @click="delExam(dgIdx, index)">删除</el-button>
                                </el-col>
                            </el-row>
                    </div>
                </el-col>

            </el-row>


        </div>

        <el-row>
            <el-col :span="8" :offset="8" v-if="degreeForm.degreeList.length > 0 && degreeForm.degreeList[0].list.length > 0" style="margin-top: 20px;">

                <el-button type="primary" :loading="loading" @click="calcDegree">下载达成度表</el-button>

            </el-col>
        </el-row>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import XLSX from "xlsx";  //引入xlsx
    import {getDomain} from "../../utils/mUtils";
    export default {
        name: 'degreeReport',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:false, uploading:false, examList: [], examFilterList: [], selExamList: [], acadyearTermList: [],
                downloadUrl: getDomain() +  `/public`,
                excelColList: [], otherScore: {cols: [], percent: 20}, curExamId: null, acadyearTerm: null,
                col: [],
                degreeForm: {name: null, degreeList: []},
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},

            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.downloadUrl = window.g.DEMO_URL;
            this.selExamList = [];
            this.tableData = [];
            this.excelColList = [];
            this.acadyearTermList = mUtils.acadyearTermList();
            this.loadExamList();
	   },
        methods: {
            loadExamList(callback){
                let search = { licenceId: this.userInfo.licenceId, schoolId: null, teacherId: null};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'exam', methodName: '/examList', pageNo: 1, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                        this.examFilterList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            examFilter(v){
                console.log("examFilter", v);
                if(v==""){
                    this.examFilterList = this.examList;
                }else{
                    this.examFilterList = XEUtils.filter(this.examList, item => item.semester == v) // [{value: 22}]
                }
            },

            addDegree(){
                if(this.degreeForm.name != null){
                    this.degreeForm.degreeList.push({name: this.degreeForm.name, curExamId: null, list: []})
                    this.degreeForm.name = null;
                }

            },
            addExam(dgIdx){
                let dgList = this.degreeForm.degreeList[dgIdx];
                let curExamId = dgList.curExamId;
                let ar = XEUtils.find(dgList.list, item => {
                    return item.id == curExamId;
                });
                if(!ar){
                    let myExam = XEUtils.find(this.examList, item => {
                        return item.id == curExamId;
                    });
                    let examName = "";
                    if(myExam) examName = myExam.name;
                    dgList.list.push({id: curExamId, name: examName, percent: 50});
                }

            },
            deleteExam(dgIdx){
                this.degreeForm.degreeList.splice(dgIdx,1);
            },
            delExam(dgIdx, idx){
                let dgList = this.degreeForm.degreeList[dgIdx];
                dgList.list.splice(idx,1);
            },

            calcDegree(){
                this.loading = true;
                let p = { degreeList: this.degreeForm.degreeList };
                let param = { controllerName: 'report/degree', methodName: '/r', param: p};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('calcDegree', data);
                    if (data) {
                        let dataList = [];
                        let head1 = ["", "", "", ];
                        let head2 = ["行政班", "学号", "姓名", ];
                        for (let i=0; i<this.degreeForm.degreeList.length; i++){
                            let dg = this.degreeForm.degreeList[i];
                            let dgName = dg.name;
                            head1.push(dgName);
                            let dgList = dg.list;
                            for (let j=0; j<dgList.length; j++){
                                let dgCell = dgList[j];
                                head2.push(dgCell.name); head2.push("满分"); head2.push("成绩/总分");
                                head1.push(""); head1.push(""); head1.push("");
                            }
                            head2.push("达成度"); head1.push("");
                        }
                        dataList.push(head1); dataList.push(head2);
                        for (let studentId in data){
                            let student = data[studentId];
                            let a = [student.hardName, student.studentNo, student.studentName];
                            for (let i=0; i<this.degreeForm.degreeList.length; i++){
                                let dg = this.degreeForm.degreeList[i];
                                let dgName = dg.name;
                                let dgList = dg.list;
                                let dacheng = 0;
                                for (let j=0; j<dgList.length; j++){
                                    let dgCell = dgList[j];
                                    let dd = student[dgName + dgCell.id];
                                    if(dd){
                                        let zb = dd.fullScore > 0?(dd.myScore/dd.fullScore): 0;
                                        dacheng = dacheng + zb * dgCell.percent / 100;
                                        a.push(dd.myScore); a.push(dd.fullScore); a.push(zb.toFixed(2));
                                    }else{
                                        a.push(""); a.push(""); a.push("");
                                    }
                                }
                                a.push(dacheng.toFixed(2));
                            }
                            dataList.push(a);
                        }
                        this.outputXlsxFile(dataList);
                        this.loading = false;
                    }

                }).catch((error) => {
                    this.loading = false;
                });
            },
            outputXlsxFile(data) {
                // console.log(data)
                let sheetNames = ['达成度'];
                let sheetsList = {};
                let wb = XLSX.utils.book_new();
                sheetsList['达成度'] = XLSX.utils.aoa_to_sheet(data);

                // console.log("sheetNames", sheetNames, sheetsList)
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;

                XLSX.writeFile(wb, "达成度.xlsx");
            },
        },


    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .degreeReport{
        .exam-info {
            text-align: left;
            margin-top: 20px;
            padding: 10px;
            border: 1px solid rgba(203, 202, 202, 0.71);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .search_container{

        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
            width: 600px !important;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
    }


</style>


